<template>
    <v-app-bar app class="bg-gradient-blue">
    <v-toolbar-title class="align-center d-flex">

            <h2 class=" text-white"><span class="align-middle pr-2"><img src="@/assets/images/logo-icon.png" width="35"></span>{{page_title}}</h2>

      
    </v-toolbar-title>
    <a href="#" class="text-white pr-4 mb-6 pt-4 position-right" @click="logout">Logout</a>

      
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
export default {
  name: "Subheader",

  components: {},

  props: {
    page_title: {
      type: String,
    },
  },
  data: () => ({
    userprofile: [
      { method:"logout", title: "Logout" }
    ],
    href() {
      return undefined;
    }
  }),

  computed: {
  },

  methods: {
    logout() {
        console.log('test');
        this.$store.dispatch('auth/logout');
    },
  },
  mounted() {
  },
};
</script>