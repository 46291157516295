<template src="./super_admin.htm"></template>


<script>
import { mapState } from 'vuex';
import SubHeader from '../../layouts/sub_header/admin_Subheader.vue';

export default {
  name: "admin",
  components: {
      'sub-header': SubHeader,
  },
  data: () => ({
    overlay: true,
    dialog: false,
    view_table: true,
    view_info: false,
    info_text: 'Accounts',
    tags: [],
    show1: false,
    show2: false,
    initForm: {
      confirm_pass: '',
      password: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      contact_number: ''
    },
    select_rules:[
      v => !!v || 'Item is required'
    ],
    rules: {
      required: value => !!value || 'Required.',
      min: value => value.length >= 8 || 'Min 8 characters',
      emailMatch: () => (`The email and password you entered don't match`),
    },
    Field_Rules: [
          v => !!v || 'Required',
        ],
    receiver_number: '0',
    emailRules: [ 
              v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
    header1: [
          {
            text: 'Account',
            align: 'start',
            sortable: false,
            value: 'account',
          },
          { text: 'Email', value: 'email' },
    ],
    users_value: [],
    
  }),
  
  created() {
    
  },
  computed: {
    passwordConfirmationRule() {
        return () => (this.new_pass === this.confirm_pass) || 'Password do not match'
      },
  },
  methods: {
    handleClick(row) {  

      //this.getPersonLogs(row.person_id);
      this.info_text = row.name;
      this.receiver_number = row.receiver;
      this.view_info = true;
    },
    closeDialog () {
        this.dialog=!this.dialog
    },
    goToList() {
    this.info_text = 'Accounts';
      this.view_info = false;
    },
    
    submit () {
      if(this.$refs.form.validate()){
        this.$api
            .post(`/admin`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
                this.dialog = false;
                this.search();
            })
            .catch(err => {
                this.dialog = false;
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            });
      }
        
    },
    submit_admin () {
      if(this.$refs.Form_Admin.validate()){
        this.$toast.open({
                    message:  'Submit Click',
                    type: 'success',
                    position: 'bottom'
                })
      }
        
    },
    search() {
        this.overlay = true;

        this.$api
          .get('/admin')
            .then(res => {
                this.users_value = res.data.data.map( user => {
                    return {
                       ...user,
                       account: user.first_name+' '+user.middle_name+' '+user.last_name
                    }
                });
                 this.overlay = false;
            })
            .catch(err => {
                this.overlay = false;
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
      },
  },
    
  mounted() {
    this.search();
  },
};
</script>